<template>
  <b-container fluid>
    <profile-status-msg :preConditionLoader="preConditionLoader" :profileStatus="profileStatus" :profile-url="'/irrigation/my-profile'" :inForm="true"/>
    <b-overlay :show="loading">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:headerTitle>
                <div class="text-center">
                    <h5 class="card-title"><i class="ri-edit-line"></i> {{ $t('farmerOperator.water_testing_request') }}</h5>
                </div>
            </template>
            <template v-slot:body>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(register)" >
                  <b-row  @click="displayProfileCheckAlert()">
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="organization"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select

                            v-model="waterTestingRequest.org_id"
                            :options="organizationList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Testing Type" vid="testing_type_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="testing_type_id"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{ $t('farmerOperator.testing_type') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select

                                  v-model="waterTestingRequest.testing_type_id"
                                  :options="testingTypeList"
                                  id="testing_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                      <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Testing Parameter" vid="parameterList" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="parameterList"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{ $t('externalUserIrrigation.water_testing_parameter') }} <span class="text-danger">*</span>
                              </template>
                              <v-select
                                  id="parameterList"
                                  v-model="parameterList"
                                  multiple
                                  :options="testingParameterList"
                                  label="text"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </v-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Applicant Name (En)" vid="name" rule="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="applicant_name_bn"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('farmerOperator.applicant_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="name"
                            readonly
                            :value="waterTestingRequest.name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Applicant Name (Bn)" vid="name_bn">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="name_bn"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('farmerOperator.applicant_name_bn') }}
                          </template>
                          <b-form-input
                            id="name_bn"
                            readonly
                            :value="waterTestingRequest.name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col lg="6" sm="12">
                      <b-form-checkbox
                          class="mt-1"
                          id="same_as_personal_address"
                          v-model="same_as_personal_address"
                          :value="true"
                          :unchecked-value="false"
                      >
                        {{ $t('externalUserIrrigation.same_as_farmer_info') }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Division" vid="far_division_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="division"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            :disabled="same_as_personal_address"
                            v-model="addressHolder.far_division_id"
                            :options="divisionList"
                            id="far_division_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="District" vid="far_district_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="district"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            :disabled="same_as_personal_address"
                            v-model="addressHolder.far_district_id"
                            :options="districtList"
                            id="far_district_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Upazila" vid="far_upazilla_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="upazila"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            :disabled="same_as_personal_address"
                            v-model="addressHolder.far_upazilla_id"
                            :options="upazilaList"
                            id="upazila_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Union" vid="far_union_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="far_union_id"
                          slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                              {{ $t('org_pro_union.union') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              :disabled="same_as_personal_address"
                              v-model="addressHolder.far_union_id"
                              :options="unionList"
                              id="far_union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                  <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="office_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('warehouseFarmer.office') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            v-model="waterTestingRequest.office_id"
                            :options="officeList"
                            id="office_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Mouza Name(En)" vid="mouza_name" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="mouza_name"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalUserIrrigation.mouza_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="mouza_name"
                            v-model="waterTestingRequest.mouza_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Mouza Name(Bn)" vid="mouza_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="mouza_name_bn"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalUserIrrigation.mouza_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="mouza_name_bn"
                            v-model="waterTestingRequest.mouza_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="JL No" vid="jl_no" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="jl"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                              {{$t('irriPumpInformation.jl_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="jl"
                            v-model="waterTestingRequest.jl_no"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12">
                      <ValidationProvider name="Plot No" vid="plot_no" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="plot_no"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                              {{$t('irriPumpInformation.plot_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="plot"
                            v-model="waterTestingRequest.plot_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Village (En)" vid="far_village" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="far_village"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('farmerOperator.village_en') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="far_village"
                                readonly
                                :value="waterTestingRequest.far_village"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Village (Bn)" vid="far_village_bn">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="far_village_bn"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('farmerOperator.village_bn') }}
                              </template>
                              <b-form-input
                                id="far_village_bn"
                                readonly
                                :value="waterTestingRequest.far_village_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="From Date" vid="from_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="from_date"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('externalUserIrrigation.from_date_schedule') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input class="form-control"
                                v-model="waterTestingRequest.from_date"
                                placeholder="Select Date"
                                id="datepicker"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="To Date" vid="to_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="to_date"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('externalUserIrrigation.to_date_schedule') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input class="form-control"
                                v-model="waterTestingRequest.to_date"
                                placeholder="Select Date"
                                id="datepicker"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-overlay :show="payLoading">
                        <table class="table table-bordered table-sm" style="min-height: 162px;margin-top: 15px">
                          <tr>
                            <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                            <th>{{ $t('externalUserIrrigation.parameter_name') }}</th>
                            <th>{{ $t('globalTrans.amount') }}</th>
                          </tr>
                          <tr v-for="(watertp, index) in waterTestingRequest.water_testing_parameter_id" :key="index">
                            <span class="d-none"></span>
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>{{ watertp.text }}</td>
                            <td>{{ watertp.amount ? $n(watertp.amount) : $n(0) }}</td>
                          </tr>
                          <tr>
                            <th class="text-center" colspan="2">{{ $t('globalTrans.total_amount') }}</th>
                            <th>{{ waterTestingRequest ? $n(waterTestingRequest.payment.amount) : $n(0) }}</th>
                          </tr>
                        </table>
                      </b-overlay>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-right">
                      <b-button @click="waterTestingRequest.final_pay = 1" type="submit" variant="primary">{{ saveBtnName}}</b-button>
                      <b-button @click="waterTestingRequest.final_pay = 0" v-if="!id" type="submit" class="ml-2" variant="warning">{{$t('externalUserIrrigation.save_draft')}}</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerInfo, waterTestingRequestStore, waterTestingRequestUpdate, getWaterTestingPayment } from '../../api/routes'
import customObject from '../../../../../store/custom'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import flatpickr from 'flatpickr'

export default {
  name: 'WaterTestingForm',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    ProfileStatusMsg
  },
  data () {
    return {
        addressHolder: {
            far_division_id: '',
            far_district_id: '',
            far_upazilla_id: '',
            far_union_id: ''
        },
        same_as_personal_address: false,
        payLoading: false,
        loadingState: true,
        profileStatus: 0,
        preConditionLoader: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('externalUserIrrigation.save_and_pay'),
        waterTestingRequest: {
          final_pay: 1,
          org_id: '',
          testing_type_id: '',
          water_testing_parameter_id: [],
          far_division_id: '',
          far_district_id: '',
          far_upazilla_id: '',
          far_union_id: '',
          name: '',
          name_bn: '',
          far_village: '',
          far_village_bn: '',
          from_date: '',
          to_date: '',
          payment: {
            amount: '',
            master_payment_id: ''
          },
          office_id: '',
          mouza_name: '',
          mouza_name_bn: '',
          jl_no: '',
          plot_no: ''
        },
        districtList: [],
        upazilaList: [],
        unionList: [],
        projectList: [],
        pumpTypeList: [],
        pumpInformationList: [],
        pumpInformationDropdownList: [],
        prices: [],
        parameterList: [],
        officeList: []
    }
  },
  created () {
    this.checkUser()
    this.getFarmerData().then(() => {
      if (this.id) {
          const tmp = this.getwaterTestingRequestData()
          this.parameterList = JSON.parse(tmp.water_testing_parameter_id)
          Object.assign(tmp, { water_testing_parameter_id: JSON.parse(tmp.water_testing_parameter_id) })
          this.waterTestingRequest = tmp
      }
      this.addressHolder.far_division_id = this.waterTestingRequest.far_division_id
      this.addressHolder.far_district_id = this.waterTestingRequest.far_district_id
      this.addressHolder.far_upazilla_id = this.waterTestingRequest.far_upazilla_id
      this.addressHolder.far_union_id = this.waterTestingRequest.far_union_id
      this.waterTestingRequest = Object.assign(this.waterTestingRequest, this.getFarmerDetails)
    })
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  computed: {
    testingParameterList: function () {
      const testingParameterList = this.$store.state.ExternalUserIrrigation.waterTestingParameterList.filter(item => item.status === 0)
      const testingType = this.waterTestingRequest.testing_type_id ? this.waterTestingRequest.testing_type_id : 0
      const orgId = this.waterTestingRequest.org_id ? this.waterTestingRequest.org_id : 0
      const data = testingParameterList.filter(item => item.org_id === orgId && item.testing_type_id === testingType)
      return data
    },
    getFarmerDetails: function () {
      return this.$store.state.ExternalUserIrrigation.farmerBasicInfo
    },
    organizationList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
    },
    testingTypeList: function () {
      const listObject = customObject.testingTypeList
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
      return tmpList
    },
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
    },
    loading () {
      return this.$store.state.ExternalUserIrrigation.static.loading
    }
  },
  watch: {
    'waterTestingRequest.org_id': function (newVal, oldVal) {
      this.getPumplist(newVal)
    },
    'addressHolder.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'addressHolder.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'addressHolder.far_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.officeList = this.getOfficeList(newVal)
    },
    parameterList: function (newVal, oldVal) {
      if (newVal) {
        this.getAmountList(newVal)
      }
    },
    same_as_personal_address: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          this.addressHolder = Object.freeze(this.waterTestingRequest)
        } else {
          this.addressHolder = {
            far_division_id: '',
            far_district_id: '',
            far_upazilla_id: '',
            far_union_id: ''
          }
        }
      }
    }
  },
  methods: {
    checkUser () {
      this.preConditionLoader = true
      RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo + '?with_data=' + false).then(response => {
        if (response.success) {
        this.profileStatus = response.data.status === 2 ? 4 : 0
        }
        this.preConditionLoader = false
      })
    },
    async getAmountList (paramList) {
      this.payLoading = true
      const orgId = this.waterTestingRequest.org_id
      const testingTypeId = this.waterTestingRequest.testing_type_id
      const param = { org_id: orgId, testing_type_id: testingTypeId, testing_parameter_id: paramList }
      const result = await RestApi.postData(irriSchemeServiceBaseUrl, getWaterTestingPayment, param)
      this.payLoading = false
      this.waterTestingRequest.water_testing_parameter_id = result.data
      this.waterTestingRequest.payment.amount = result.total_amount
    },
    getwaterTestingRequestData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
      // return this.$store.state.ExternalUserIrrigation.waterTestingRequests.find(item => item.id === this.id)
    },
    async register () {
      if (this.profileStatus === 0) {
        this.displayProfileCheckAlert()
        return
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true })

        this.waterTestingRequest.far_division_id = this.addressHolder.far_division_id
        this.waterTestingRequest.far_district_id = this.addressHolder.far_district_id
        this.waterTestingRequest.far_upazilla_id = this.addressHolder.far_upazilla_id
        this.waterTestingRequest.far_union_id = this.addressHolder.far_union_id

      if (this.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${waterTestingRequestUpdate}/${this.id}`, this.waterTestingRequest)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, waterTestingRequestStore, this.waterTestingRequest)
      }
      loadingState.listReload = true
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false })
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)
        if (this.id) {
          this.$toast.success({
            title: 'Success',
            message: result.message
          })
          this.$bvModal.hide('modal-4')
        } else {
          if (this.waterTestingRequest.final_pay === 1) {
            window.location = result.url
          } else {
            this.$toast.success({
              title: 'Success',
              message: result.message
            })
            this.$bvModal.hide('modal-4')
          }
        }
        this.$router.push('/irrigation/water-testing-request-list')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
      }
    },
    getPumplist (orgId) {
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-type/list-all').then(response => {
        const objectData = response.data.filter(pumpType => pumpType.org_id === orgId)
        this.pumpTypeList = objectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.pump_type_name_bn }
          } else {
            return { value: obj.id, text: obj.pump_type_name }
          }
        })
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0)

      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }

      return unionList
    },
    async getFarmerData () {
      return await RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo).then(async (response) => {
          if (response.success) {
              this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
              this.loadingState = false
          }
      })
    },
    getOfficeList (upazilaId = null) {
      const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(office => office.district_id === this.addressHolder.far_district_id && office.status === 0)
      if (upazilaId) {
          const tmpOfficeList = officeList.filter(item => item.upazilla_id === upazilaId && item.status === 0)
          if (tmpOfficeList.length > 0) {
            return tmpOfficeList
          }
      }
      return officeList
    },
    displayProfileCheckAlert () {
      if (this.profileStatus !== 0) {
        return
      }
      // const msg = this.$t('externalPanel.profile_complete_msg_part1') + '<a href="/irrigation/my-profile">links</a> ' + this.$t('externalPanel.profile_complete_msg_part2')
      this.$swal({
        title: this.$t('externalPanel.profile_complete_msg'),
        showCloseButton: true,
        confirmButtonText: 'Close',
        // showCancelButton: true,
        // html: msg
        focusConfirm: false
      })
    }
  }
}
</script>
