<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <h4 class="text-bold">{{ $t('externalUserIrrigation.water_testing_parameter') }}</h4>
                          <table class="table table-bordered table-sm mt-1">
                            <tr>
                              <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                              <th>{{ $t('externalUserIrrigation.parameter_name') }}</th>
                              <th>{{ $t('globalTrans.amount') }}</th>
                            </tr>
                            <tr v-for="(watertp, index) in columnPayment" :key="index">
                              <span class="d-none"></span>
                              <td class="text-center">{{ $n(index + 1) }}</td>
                              <td>{{ watertp.text }}</td>
                              <td>{{ $n(watertp.amount) }}</td>
                            </tr>
                            <tr>
                              <th class="text-center" colspan="2">{{ $t('globalTrans.total_amount') }}</th>
                              <th>{{ $n(totalAmount) }}</th>
                            </tr>
                        </table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.waterTestingRequest = this.item
  },
  data () {
    return {
      waterTestingRequest: {},
      slOffset: 1
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('complain.organization'), val: this.waterTestingRequest.org_name_bn, key1: this.$t('farmerOperator.applicant_name'), val1: this.waterTestingRequest.name_bn },
          { key: this.$t('complain.division'), val: this.waterTestingRequest.division_name_bn, key1: this.$t('complain.district'), val1: this.waterTestingRequest.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.waterTestingRequest.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.waterTestingRequest.union_name_bn },
          { key: this.$t('farmerOperator.village'), val: this.waterTestingRequest.far_village_bn, key1: this.$t('globalTrans.from_date'), val1: this.$d(new Date(this.waterTestingRequest.from_date)) },
          { key: this.$t('farmerOperator.sample_number'), val: this.$n(this.waterTestingRequest.sample_number, { useGrouping: false }), key1: this.$t('globalTrans.to_date'), val1: this.$d(new Date(this.waterTestingRequest.to_date)) },
          { key: this.$t('farmerOperator.testing_type'), val: this.waterTestingRequest.testing_type_name_bn, key1: this.$t('farmerOperator.sample_serial'), val1: this.$n(this.waterTestingRequest.sample_serial, { useGrouping: false }) },
          { key: this.$t('globalTrans.status'), val: this.waterTestingRequest.testing_status_name_bn, key1: this.$t('farmerOperator.farmer_id'), val1: this.$n(this.waterTestingRequest.farmer_id, { useGrouping: false }) },
          { key: this.$t('externalUserIrrigation.application_id'), val: this.waterTestingRequest.application_id, key1: this.$t('irriPumpInformation.mouza_no'), val1: this.waterTestingRequest.mouza_name_bn },
        { key: this.$t('irriPumpInformation.jl_no'), val: this.waterTestingRequest.jl_no, key1: this.$t('irriPumpInformation.plot_no'), val1: this.waterTestingRequest.plot_no }
        ]
      } else {
          return [
            { key: this.$t('complain.organization'), val: this.waterTestingRequest.org_name, key1: this.$t('farmerOperator.applicant_name'), val1: this.waterTestingRequest.name },
            { key: this.$t('complain.division'), val: this.waterTestingRequest.division_name, key1: this.$t('complain.district'), val1: this.waterTestingRequest.district_name },
            { key: this.$t('complain.upazila'), val: this.waterTestingRequest.upazilla_name, key1: this.$t('complain.union'), val1: this.waterTestingRequest.union_name },
            { key: this.$t('farmerOperator.village'), val: this.waterTestingRequest.far_village, key1: this.$t('globalTrans.from_date'), val1: this.waterTestingRequest.from_date },
            { key: this.$t('farmerOperator.sample_number'), val: this.waterTestingRequest.sample_number, key1: this.$t('globalTrans.to_date'), val1: this.waterTestingRequest.to_date },
            { key: this.$t('farmerOperator.testing_type'), val: this.waterTestingRequest.testing_type_name, key1: this.$t('farmerOperator.sample_serial'), val1: this.waterTestingRequest.sample_serial },
            { key: this.$t('globalTrans.status'), val: this.waterTestingRequest.testing_status_name, key1: this.$t('farmerOperator.farmer_id'), val1: this.waterTestingRequest.farmer_id },
            { key: this.$t('externalUserIrrigation.application_id'), val: this.waterTestingRequest.application_id, key1: this.$t('irriPumpInformation.mouza_no'), val1: this.waterTestingRequest.mouza_name },
        { key: this.$t('irriPumpInformation.jl_no'), val: this.waterTestingRequest.jl_no, key1: this.$t('irriPumpInformation.plot_no'), val1: this.waterTestingRequest.plot_no }
          ]
      }
    },
    columnPayment () {
      const payments = JSON.parse(this.waterTestingRequest.water_testing_parameter_id)
      return payments
    },
    totalAmount () {
      const payments = JSON.parse(this.waterTestingRequest.water_testing_parameter_id)
      let totalAmount = 0
      payments.map(item => {
        totalAmount += item.amount
      })
      return totalAmount
    }
  },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
