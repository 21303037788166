<template>
  <b-container fluid>
    <b-row v-if="loadMsg">
        <b-col>
            <b-overlay :show='true'>
                <h4 class="text-center">{{ $t('globalTrans.loading') }} ...</h4>
            </b-overlay>
        </b-col>
    </b-row>
    <div v-else>
        <b-row v-if="overlay" >
            <div class="box-message col-md-12 mt-5 text-danger">
                <div class="arrow">
                    <div class="outer"></div>
                    <div class="inner"></div>
                </div>
                <div class="message-body">
                    <p class="pb-2 pt-2 h5"><i class="ri-alert-fill mr-2"></i>
                      <!-- {{ $t('externalUserIrrigation.dashboard_message') }} -->
                      {{ $t('externalPanel.profile_complete_msg_part1')}}
                      <router-link :to="{ path: '/irrigation/my-profile' }">
                        {{ $t('externalPanel.profile_complete_msg_part2')}}
                      </router-link>
                      {{ $t('externalPanel.profile_complete_msg_part3')}}
                    </p>
                </div>
            </div>
        </b-row>
        <b-row v-else>
        <b-col md="12">
            <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('farmerOperator.water_testing_request') }}</h4>
            </template>
            <template v-slot:headerAction>
                <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                {{  $t('globalTrans.add_new') }}
                </b-button> -->
                <router-link class="btn btn-primary" :to="{ name: 'external-user.water_testing_request_form', params: { id: 0 }}">{{  $t('globalTrans.add_new') }}</router-link>
            </template>
            <template v-slot:body>
                <b-overlay :show="loadingState">
                <b-row>
                    <b-col md="12" class="table-responsive">
                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                        <template v-slot:cell(index)="data">
                          {{ $n(data.index + pagination.slOffset) }}
                        </template>
                        <template v-slot:cell(division_name)="data">
                          <span>{{ data.item.ontime | formatDate }}</span>
                        </template>
                        <template v-slot:cell(sample_number)="data">
                          <span>{{ $n(data.item.sample_number, { useGrouping: false }) }}</span>
                        </template>
                        <template v-slot:cell(payment_status)="data">
                          <span>{{ data.item.payment_status === 1 ? $t('globalTrans.paid') : $t('globalTrans.unpaid') }}</span>
                        </template>
                        <template v-slot:cell(view)="data">
                          <b-button class="btn btn-success" @click="pdfExport(data.item)">{{ $t('globalTrans.pdf') }}</b-button>
                        </template>
                        <template v-slot:cell(address)="data">
                          {{ getAddress(data.item) }}
                        </template>
                        <template v-slot:cell(action)="data">
                          <b-button class="mr-1" title="View" v-b-modal.modal-detail variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                          <b-button v-if="data.item.payment !== null && data.item.payment.pay_status === 'pending'" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" title="Edit"><i class="ri-ball-pen-fill m-0"></i></b-button>
                          <b-button v-if="data.item.payment !== null && data.item.payment.pay_status === 'pending'" v-b-modal.modal-payment variant=" iq-bg-success mr-1 mb-1" size="sm" @click="payment(data.item)" title="Payment">{{ $t('externalUserIrrigation.payment') }}</b-button>
                          <a v-if="data.item.status === 5 && data.item.water_test_reports != null" :href="irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/water-test-reports/original/' + data.item.water_test_reports.attachment" class="btn btn-success" title="Attachment"><i class="fa fa-cloud" aria-hidden="true"></i></a>
                          <b-button v-if="listData[0].rating_status !== 1" class="btn-outline-success ml-1" title="Rate This Application" v-b-modal.modal-rating variant=" iq-bg-success" size="sm" @click="rating(listData[0])"><i class="ri-star-line m-0" aria-hidden="true"></i></b-button>
                          <b-button v-if="data.item.payment_status === 1" class="btn-outline-success mt-1" variant=" iq-bg-success ml-1" size="sm" @click="showMoneyReceipt(data.item.id, 4, 1)" title="Money Receipt"><i class="ri-money-dollar-box-line m-0"></i></b-button>
                          <b-button v-if="data.item.status === 4" v-b-modal.report-modal class="btn-outline-success mt-1" variant=" iq-bg-success ml-1" size="sm" @click="reportRetails(data.item)" title="Report Details"><i class="ri-file-chart-line m-0"></i></b-button>
                        </template>
                    </b-table>
                    <b-pagination
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        />
                    </b-col>
                </b-row>
                </b-overlay>
            </template>
            </iq-card>
        </b-col>
        </b-row>
    </div>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="report-modal" size="xl" :title="$t('irriWaterTest.water_testing_report')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <ReportModal :id="editItemId" :key="editItemId" :item="item"/>
    </b-modal>
    <b-modal id="modal-detail" size="lg" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <b-button @click="pdfExport(item)" class="ml-4 btn-success water-test-pdf-button">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
        <DetailModal :id="editItemId" :key="editItemId" :item="item"/>
    </b-modal>
    <b-modal id="modal-payment" size="md" :title="$t('externalUserIrrigation.payment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Payment :singleData="singleTest" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-rating" size="md" :title="$t('external_sidebar.rating')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Rating :application="singleApp"/>
    </b-modal>
    <b-modal id="modal-money-receipt" size="md" :title="$t('externalUserIrrigation.money_receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <MoneyReceipt :appId="currentAppId" :applicationType="applicationType" :paymentTypeTd="paymentTypeTd" />
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form'
import ReportModal from './Report'
import DetailModal from './DetailModal'
import Payment from './Payment'
import { waterTestingRequestList, waterTestingRequestToggleStatus, irrigationPayment, farmerInfo } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ExportPdf from '@/Utils/export-pdf'
import customObject from '../../../../../store/custom'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { helpers } from '@/mixins/helper-functions'
import Rating from '../../components/Rating'
import MoneyReceipt from './MoneyReceipt'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV,
    ReportModal,
    DetailModal,
    Payment,
    Rating,
    MoneyReceipt
  },
  data () {
    return {
      irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
      search: {
        org_id: '0',
        auth_id: this.$store.state.Auth.authUser.id,
        far_division_id: '0',
        far_district_id: '0',
        far_upazilla_id: '0',
        far_union_id: '0',
        status: '0',
        sample_number: '',
        testing_type_id: '0'
      },
      overlay: true,
      item: '',
      singleTest: null,
      singleApp: null,
      pumpInformationDropdownList: [],
      rows: [],
      projectList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      loadMsg: false,
      currentAppId: 0,
      applicationType: 0,
      paymentTypeTd: 0
    }
  },
  computed: {
    organizationList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
    },
    testingTypeList: function () {
      const ObjectData = customObject.testingTypeList
      return ObjectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.name_bn }
          } else {
            return { value: obj.id, text: obj.name }
          }
        })
    },
    waterTestingStatusList: function () {
      const ObjectData = customObject.waterTestingStatusList
      return ObjectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.name_bn }
          } else {
            return { value: obj.id, text: obj.name }
          }
        })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('farmerOperator.water_testing_request_entry') : this.$t('farmerOperator.water_testing_request') + ' ' + this.$t('globalTrans.modify')
    },
    detailTitle () {
       return this.$t('farmerOperator.water_testing_request_detail')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.application_id'), class: 'text-left' },
          { label: this.$t('farmerOperator.applicant_name'), class: 'text-left' },
          { label: this.$t('org_pro.organization'), class: 'text-left' },
          { label: this.$t('farmerOperator.testing_type'), class: 'text-left' },
          { label: this.$t('globalTrans.address'), class: 'text-left' },
          { label: this.$t('farmerOperator.sample_number'), class: 'text-left' },
          { label: this.$t('globalTrans.view_application'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.payment_status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_bn' },
          { key: 'org_name_bn' },
          { key: 'testing_type_name_bn' },
          { key: 'address' },
          { key: 'sample_number' },
          { key: 'view' },
          { key: 'testing_status_name_bn' },
          { key: 'payment_status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name' },
          { key: 'org_name' },
          { key: 'testing_type_name' },
          { key: 'address' },
          { key: 'sample_number' },
          { key: 'view' },
          { key: 'testing_status_name' },
          { key: 'payment_status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
    }
  },
  watch: {
    'search.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.far_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  created () {
    this.checkUser().then((res) => {
        if (!res.loggedFarmerId) {
              this.ifApply = true
        } else {
            this.loadData()
        }
    })
    this.paymentStatusUpdate()
  },
  methods: {
    rating (item) {
        this.singleApp = item
        this.singleApp.application_type = 4
        this.$bvModal.show('modal-rating')
    },
    getAddress (item) {
      const isBn = this.$i18n.locale === 'bn'
      const commonObj = this.$store.state.ExternalUserIrrigation.commonObj
      const divisionEnBn = helpers.findEnBn(commonObj.divisionList,
          item.far_division_id, 'value', 'text_en', 'text_bn')
      const districtEnBn = helpers.findEnBn(commonObj.districtList,
          item.far_district_id, 'value', 'text_en', 'text_bn')
      const upazillaEnBn = helpers.findEnBn(commonObj.upazilaList,
          item.far_upazilla_id, 'value', 'text_en', 'text_bn')
      const unionEnBn = helpers.findEnBn(commonObj.unionList,
          item.far_union_id, 'value', 'text_en', 'text_bn')

      return (isBn ? item.far_village_bn : item.far_village) +
          ', ' +
          unionEnBn +
          ', ' +
          upazillaEnBn +
          ', ' +
          districtEnBn +
          ', ' +
          divisionEnBn
    },
    downloadAttachment (item) {
      var fileURL = irriSchemeServiceBaseUrl + 'storage/uploads/water-test-reports/original/' + item.attachment
      window.open(fileURL)
    },
    pdfExport (data) {
      const rowData = this.getPdfData(data)
      const extraData = {
        totalHeaderRows: 0
      }
      const reportTitle = this.$i18n.locale === 'en' ? 'Water Testing Request Report' : 'পানি পরীক্ষার অনুরোধের প্রতিবেদন'
      // const columnWids = ['*', '*', '*', '*']
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', data.org_id, reportTitle, rowData, null, extraData)
    },
    getPdfData (data) {
      if (this.$i18n.locale === 'bn') {
        const rowData = [
          [
            { text: this.$t('complain.organization') }, { text: data.org_name_bn },
            { text: this.$t('farmerOperator.applicant_name') }, { text: data.name_bn }
          ],
          [
            { text: this.$t('complain.division') }, { text: data.division_name_bn },
            { text: this.$t('complain.district') }, { text: data.district_name_bn }
          ],
          [
            { text: this.$t('complain.upazila') }, { text: data.upazilla_name_bn },
            { text: this.$t('complain.union') }, { text: data.union_name_bn }
          ],
          [
            { text: this.$t('farmerOperator.village') }, { text: data.far_village_bn },
            { text: this.$t('globalTrans.from_date') }, { text: this.$d(new Date(data.from_date)) }
          ],
          [
            { text: this.$t('farmerOperator.sample_number') }, { text: this.$n(data.sample_number, { useGrouping: false }) },
            { text: this.$t('globalTrans.to_date') }, { text: this.$d(new Date(data.to_date)) }
          ],
          [
            { text: this.$t('farmerOperator.testing_type') }, { text: data.testing_type_name_bn },
            { text: this.$t('farmerOperator.sample_serial') }, { text: this.$n(data.sample_serial, { useGrouping: false }) }
          ],
          [
            { text: this.$t('globalTrans.status') }, { text: data.testing_status_name_bn },
            { text: this.$t('externalUserIrrigation.application_id') }, { text: data.application_id }
          ]
        ]
        return rowData
      } else {
        const rowData = [
          [
            { text: this.$t('complain.organization') }, { text: data.org_name },
            { text: this.$t('farmerOperator.applicant_name') }, { text: data.name }
          ],
          [
            { text: this.$t('complain.division') }, { text: data.division_name },
            { text: this.$t('complain.district') }, { text: data.district_name }
          ],
          [
            { text: this.$t('complain.upazila') }, { text: data.upazilla_name },
            { text: this.$t('complain.union') }, { text: data.union_name }
          ],
          [
            { text: this.$t('farmerOperator.village') }, { text: data.far_village },
            { text: this.$t('globalTrans.from_date') }, { text: this.$d(new Date(data.from_date)) }
          ],
          [
            { text: this.$t('farmerOperator.sample_number') }, { text: this.$n(data.sample_number, { useGrouping: false }) },
            { text: this.$t('globalTrans.to_date') }, { text: this.$d(new Date(data.to_date)) }
          ],
          [
            { text: this.$t('farmerOperator.testing_type') }, { text: data.testing_type_name },
            { text: this.$t('farmerOperator.sample_serial') }, { text: this.$n(data.sample_serial, { useGrouping: false }) }
          ],
          [
            { text: this.$t('globalTrans.status') }, { text: data.testing_status_name },
            { text: this.$t('externalUserIrrigation.application_id') }, { text: data.application_id }
          ]
        ]
        return rowData
      }
    },
    reportRetails (item) {
      this.item = item
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    payment (item) {
      this.singleTest = item
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    toggleStatus (item) {
      RestApi.deleteData(irriSchemeServiceBaseUrl, `${waterTestingRequestToggleStatus}/${item.id}`).then(response => {
        if (response.success) {
          this.$store.dispatch('ExternalUserIrrigation/togglewaterTestingRequestStatus', item)
          this.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
        }
      })
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, waterTestingRequestToggleStatus, item, 'IrriConfig', 'waterTestingRequestList')
    },
    async checkUser () {
        this.loadMsg = true
        return await RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo + '?with_data=' + false).then(response => {
            this.loadMsg = false
            if (response.success) {
                this.overlay = false
                this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
                return { loggedFarmerId: response.data.id }
            }
        })
    },
    loadData () {
      this.loadMsg = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      RestApi.getData(irriSchemeServiceBaseUrl, waterTestingRequestList, params).then(response => {
        this.loadMsg = false
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data.data))
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const commonObj = this.$store.state.ExternalUserIrrigation.commonObj
      const organizationList = this.organizationList
      const divisionList = commonObj.divisionList
      const districtList = commonObj.districtList
      const upazilaList = commonObj.upazilaList
      const unionList = commonObj.unionList
      const testingTypeList = customObject.testingTypeList
      const waterTestingStatusList = customObject.waterTestingStatusList
      return data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.far_division_id)
        const districtObject = districtList.find(district => district.value === item.far_district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.far_upazilla_id)
        const unionObject = unionList.find(union => union.value === item.far_union_id)
        const testingTypeObject = testingTypeList.find(testingType => testingType.id === item.testing_type_id)
        const statusListObject = waterTestingStatusList.find(testingStatus => testingStatus.id === item.status)
        const orgData = {
          org_name: orgObject.text,
          org_name_bn: orgObject.text_bn
        }
        const divisionData = {
          division_name: divisionObject.text,
          division_name_bn: divisionObject.text_bn
        }
        const districtData = {
          district_name: districtObject.text,
          district_name_bn: districtObject.text_bn
        }
        const upazillaData = {
          upazilla_name: upazilaObject.text,
          upazilla_name_bn: upazilaObject.text_bn
        }
        const unionData = {
          union_name: unionObject.text,
          union_name_bn: unionObject.text_bn
        }
        const TestingTypeData = {
          testing_type_name: testingTypeObject.name,
          testing_type_name_bn: testingTypeObject.name_bn
        }
        const waterTestingData = {
          testing_status_name: statusListObject.name,
          testing_status_name_bn: statusListObject.name_bn
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, TestingTypeData, waterTestingData)
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    async paymentStatusUpdate () {
      if (this.$route.params.status && this.$route.query.transId) {
        const status = this.$route.params.status
        const tranId = this.$route.query.transId
        await RestApi.getData(irriSchemeServiceBaseUrl, irrigationPayment + status, { transId: tranId }).then(response => {
          if (response.success) {
            this.$toast.success({
              title: 'Success',
              message: response.message,
              color: '#D6E09B'
            })
          } else {
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
        })
        this.loadData()
        this.$router.push('/irrigation/water-testing-request-list')
      }
    },
    showMoneyReceipt (itemId, appType, paymentTypeTd) {
      this.currentAppId = itemId
      this.applicationType = appType
      this.paymentTypeTd = paymentTypeTd
      this.$bvModal.show('modal-money-receipt')
    }
  }
}
</script>
